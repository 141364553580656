import React from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, HStack, Text } from '@chakra-ui/react';

import { VectorBackground, Icon } from 'components/view';
import { MetaData } from 'components/meta';
import { LinkTranslate } from 'components/navigation';
import { DefaultLayout, Card } from 'components/layout';

const Section404 = () => {
    const { t } = useTranslation();
    return (<Container
        maxW="container.sm"
        p={16}
        pt={{ md: '7rem'}}>
        <Card p={16} alignItems="center" gap={2}>
            <HStack>
                <Icon name="bx-message-alt-error" size="lg" color="primary.300" />
                <h1 noOfLines={1}>{t('title')}</h1>
            </HStack>
            <Text textAlign="center">
                <Trans i18nKey="description" t={t} components={[<LinkTranslate to="/" isExternal />]} />
            </Text>
        </Card>
    </Container>);
};

const NotFoundPage = ({ location }) => {
    return (<>
        <MetaData location={location} />
        <DefaultLayout>
            <VectorBackground
                scale={6}
                pattern="plaid">
                <Section404 />
            </VectorBackground>
        </DefaultLayout>
    </>)
};

export default NotFoundPage;

export const PageNotFoundPageQuery = graphql`
query PageNotFoundPageQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["common", "404"]}}) {
        ...TranslationFragment
    }
}`;
